<template>
  <div>
  <b-card-actions ref="card" title="" action-refresh @refresh="refresh">
    <b-row>
      <b-col cols="8">
        <template #title>
          <feather-icon icon="HomeIcon"/>
          <span>  {{ $t('Fulfillment') }}
</span>
        </template>

        <div>
          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>

              <!-- Field: Description -->
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Description')"
                    label-for="description"
                >
                  <b-form-input
                      id="description"
                      v-model="company_fulfillment.fulfillment.description"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row v-if="false">
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Legal Regulation')"
                    label-for="legal_regulation"
                >

                  <vue-autosuggest
                      :disabled="allEditDisabled"
                      :suggestions="filteredOptions"
                      :limit="10"
                      :input-props="{id:'autosuggest__input',class:'form-control'}"
                      id="legal_regulation"
                      @input="onInputChange"
                      @selected="onSuggestionSelection"
                      v-model="legal_regulation_title"
                      disabled="true"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item.title }}</span>
                    </template>
                  </vue-autosuggest>


                </b-form-group>
              </b-col>

            </b-row>
            <b-row>

              <!-- Field: Type -->
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Type')"

                    label-for="type"
                >

                  <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :text="$t(company_fulfillment.fulfillment.fulfillment_type)"
                      variant="outline-primary"
                      class="button-inline-spacing-2"
                      block
                      :disabled="true"
                  >
                    <b-dropdown-item
                        v-for="type in types"
                        :key="type"
                        @click="setType(type)"
                    >
                      <span class="ml-50">{{ $t(type) }}</span>
                    </b-dropdown-item>

                  </b-dropdown>


                </b-form-group>
              </b-col>


            </b-row>

            <b-row v-if="company_fulfillment.fulfillment.fulfillment_type === 'periodic'">

              <!-- Field: Frequency -->
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Frequency in days')"
                    label-for="frequency"
                >
                  <b-form-input
                      id="frequency"
                      v-model="company_fulfillment.fulfillment.frequency"
                  />
                </b-form-group>
              </b-col>

            </b-row>


            <b-row>

              <!-- Field: Status -->
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Status')"

                    label-for="status"
                >
                  <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :text="$t(company_fulfillment.status)"
                      variant="outline-primary"
                      class="button-inline-spacing-2"
                      block
                      :disabled="allEditDisabled"
                  >
                    <b-dropdown-item
                        v-for="status in status_list"
                        :key="status"
                        @click="setStatus(status)"
                    >
                      <span class="ml-50">{{ $t(status) }}</span>
                    </b-dropdown-item>

                  </b-dropdown>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                  cols="12"
                  md="12"
                  v-if="company_fulfillment.fulfillment.fulfillment_type === 'initial'"

              >
                <b-form-group
                    :label="$t('Expected completion date')"
                    label-for="expected_completion_date"
                >
                  <flat-pickr
                      id="delivery_date"
                      v-model="company_fulfillment.expected_completion_date"
                      class="form-control"
                      :disabled="allEditDisabled"
                      :config="{dateFormat: 'd/m/Y'}"

                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="6"
                  v-if="false && company_fulfillment.fulfillment.fulfillment_type === 'initial'"
              >
                <b-form-group
                    :label="$t('Effective completion date')"
                    label-for="effective_completion_date"
                >
                  <flat-pickr
                      id="delivery_date"
                      v-model="company_fulfillment.effective_completion_date"
                      class="form-control"
                      :disabled="allEditDisabled"
                      :config="{dateFormat: 'd/m/Y'}"

                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  v-if="company_fulfillment.fulfillment.fulfillment_type === 'periodic'"
              >
                <b-form-group
                    :label="$t('Expiration Date')"
                    label-for="expiration_date"
                >
                  <flat-pickr
                      id="expiration_date"
                      v-model="company_fulfillment.expiration_date"
                      class="form-control"
                      :disabled="allEditDisabled"
                      :config="{dateFormat: 'd/m/Y'}"

                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
                <b-col
                    cols="12"
                    md="12"
                    v-if="company_fulfillment.fulfillment.fulfillment_type === 'initial'"

                >
                  <b-form-group
                      :label="$t('Note')"
                      label-for="note"
                  >
                    <b-form-textarea
                        rows="2"
                        id="note"
                        name="link"
                        v-model="company_fulfillment.note"
                        :disabled="allEditDisabled"
                    />
                  </b-form-group>
                </b-col>
            </b-row>
          </b-form>


          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="handleSubmit()"
              :disabled="allEditDisabled"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="back($event)"
          >
            {{ $t('Cancel') }}


          </b-button>
        </div>


      </b-col>
      <b-col cols="4">
        <b-card
            :title="legal_regulation.title"
            class="mb-3"
        >
          <b-card-text>
            <dl class="row">
              <dt class="col-sm-3">
                {{ $t('Area') }}
              </dt>
              <dd class="col-sm-9">
                {{ legal_regulation.area }}
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-3">
                {{ $t('field') }}
              </dt>
              <dd class="col-sm-9">
                {{ legal_regulation.field }}
              </dd>
            </dl>

          </b-card-text>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :href="legal_regulation.link"
              target="_blank"
          >
            {{ $t('Link') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-card-actions>
    <company-fulfillment-documents-list-component v-if="this.$route.params.id !== 'new'"/>
  </div>


</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BCardText,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BFormTextarea,
  BTable,
  BTabs,
  BTab,
  BCardHeader,
  BDropdown,
  BDropdownItem,

  BCardTitle, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import Ripple from 'vue-ripple-directive'
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import Spacer from "@/views/components/spacer/Spacer";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import EquipmentService from "@/basic/equipmentService";
import LegalService from "@/basic/legalService";
import useUsersList from "@/views/apps/user/users-list/useUsersList";
import {ref} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import vSelect from 'vue-select'
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import ability from "@/libs/acl/ability";
import {VueAutosuggest} from 'vue-autosuggest'
import CompanyFulfillmentDocumentsListComponent from "@/views/table/vue-good-table/CompanyFulfillmentDocumentsListComponent";

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BAvatar,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BMedia,
    BTabs,
    BDropdown,
    BTab,
    BDropdownItem,
    flatPickr,
    Spacer,
    vSelect,
    BFormFile,
    Cropper,
    VueAutosuggest,
    CompanyFulfillmentDocumentsListComponent,
  },
  directives: {
    Ripple,
  },

  data() {
    return {

      company_fulfillment: {
        fulfillment: {
          description: "",
          fulfillment_type: "",
          status: "",
          legal_regulation: {title: ""},
          legal_regulation_id: 0
        },
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: false,
      allEditDisabled: !ability.can("manage", "Basic Legal Obligations"),
      department_new_id: 1,
      role_new_id: 1,
      status_list: ["open", "to_be_started", "suspended", "closed"],
      types: ["initial", "periodic"],
      dateDefault: null,
      assignment_options: ['a', 'b', 'c'],
      assignment_selected: [],
      url: "",
      variant: "light",

      tab: this.$route.params.tab ? this.$route.params.tab : "main",
      tabIndex: 1,
      suggestions: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
      legal_regulations: [],
      legal_regulation_title: "",
      legal_regulation: {area: '', title: "", link: "", field: "", type: null},
      legal_regulation_company_mapping: {},
      from_company_id: 0,
      copy_from: this.$route.params.copy_from ? this.$route.params.copy_from : "none",
      copy_from_fulfillment:null,

    }
  },
  computed: {},
  methods: {

    onSuggestionSelection(selectedItem) {
      console.log('selectedItem', selectedItem)
      this.company_fulfillment.fulfillment.legal_regulation_id = selectedItem.item.id
      this.legal_regulation_title = selectedItem.item.title
      this.legal_regulation = selectedItem.item
    },
    onInputChange(text) {

      console.log("onInputChange Text", text)
      console.log("onInputChange filteredOptions", this.filteredOptions)
      if (text === '' || text === undefined) {
        this.filteredOptions = [{
          data: this.legal_regulations,
        }]
      }

      const filteredData = this.legal_regulations.filter(item => item.title.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },

    refresh() {


      const legalService = new LegalService(useJwt)

      let company_fulfillment_id = this.$route.params.id;
      console.log("refresh company_fulfillment_id", company_fulfillment_id)
      let legal_regulation_id = this.$route.params.legal_regulation_id;
      console.log("refresh legal_regulation_id", legal_regulation_id)
      let type = this.$route.params.type;
      console.log("refresh type", type)
      console.log("refresh copy_from", this.copy_from)

      this.$refs["card"].showLoading = true

      if (company_fulfillment_id !== 'new') {
        legalService.company_fulfillment(company_fulfillment_id).then(response => {

          console.log("refresh response", response)
          if (response.data.data.company_fulfillment) {
            console.log("fulfillment", response.data.data.company_fulfillment)
            this.documents_enabled = true;
            this.company_fulfillment = response.data.data.company_fulfillment;
            this.legal_regulation_title = this.company_fulfillment.fulfillment.legal_regulation.title
            this.legal_regulation = this.company_fulfillment.fulfillment.legal_regulation
            this.legal_regulation_company_mapping = response.data.data.legal_regulation_company_mapping

            console.log("this.legal_regulation", this.legal_regulation)
          } else {
            console.log('fulfillment not found')
          }

          if (response.data.data.legal_regulations) {
            this.legal_regulations = response.data.data.legal_regulations;
            this.filteredOptions = [{data: this.legal_regulations,}];

          } else {
            console.log('legal_regulations not found')
          }




          console.log('tabIndex s', this.tabIndex)

          console.log('data', response.data.data)
          this.$refs["card"].showLoading = false
          console.log("refresh filteredOptions", this.filteredOptions)

        })

      } else {

        if (this.copy_from !== "none") {
          console.log("--x--->copy_from", this.copy_from)
          legalService.fulfillment(this.copy_from).then(response => {
            if (response.data.data.fulfillment) {
              console.log("----->copy_from fulfillment", response.data.data.fulfillment)
              this.copy_from_fulfillment = response.data.data.fulfillment
              this.company_fulfillment.fulfillment.description = this.copy_from_fulfillment.description
            }
            else{

            }

          });

        }


        legalService.legal_regulation(legal_regulation_id).then(response => {
          console.log("legal_regulation", response.data.data.legal_regulation)
          this.legal_regulation = response.data.data.legal_regulation
          this.legal_regulation_title = this.legal_regulation.title
          this.legal_regulations = [this.legal_regulation]
          this.filteredOptions = [{data: this.legal_regulations,}];
          this.$refs["card"].showLoading = false
          console.log("refresh filteredOptions", this.filteredOptions)
        })

        this.company_fulfillment.fulfillment.fulfillment_type = type;
        this.$refs["card"].showLoading = false

      }


    },
    async back(e) {
      console.log("pushing back to company-fulfillments: " + this.company_fulfillment.fulfillment.legal_regulation.id)
      //this.$router.push({name: 'company-fulfillments',params: {legal_regulation_id: this.company_fulfillment.fulfillment.legal_regulation.id}})
      this.$router.back();
    },
    setStatus(status) {
      this.company_fulfillment.status = status;
    },
    setType(type) {
      this.company_fulfillment.fulfillment.fulfillment_type = type;
    },


    async handleSubmit(e) {
      const legalService = new LegalService(useJwt)
      this.submitted = true;
      //const valid = await this.$validator.validate();
      const valid = true;

      if (!valid) {
        this.$vs.notify({
          title: this.$t("create_admin.FailedToCreateAdmin"),
          text: this.$t("create_admin.ConfirmPasswordFailed"),
          color: "danger",
          position: "top-center"
        });
        this.submitted = false;
        return false;
      }

      this.$refs["card"].showLoading = true
      this.company_fulfillment.fulfillment.legal_regulation = this.legal_regulation
      this.company_fulfillment.fulfillment.legal_regulation_id = this.legal_regulation.id
      const data = {
        company_fulfillment: this.company_fulfillment,
      };


      try {
        console.log('data', data)
        let fulfillment_id = this.$route.params.id;
        let company_id = store.state.userCompany.id;

        legalService.update_company_fulfillment(fulfillment_id, data).then(response => {
          console.log("response", response)
          this.$refs["card"].showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('Fulfillment'),
              icon: 'AlertIcon',
              variant: 'success',
              text: i18n.t('Fulfillment saved with success'),
            },
          })
          this.$router.push({name: 'company-fulfillments', params: {'company_id': company_id, 'legal_regulation_id': this.legal_regulation.id}})
        })


      } catch (error) {

      }
      this.submitted = false;
    },

  },
  mounted() {
    this.refresh()
  },
  setup(props) {
    const {resolveUserRoleVariant} = useUsersList()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.avatar = base64
      console.log("avatar", props.avatar)
    })

    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
